@import 'styles/partials/_variables.module.scss';

.iconButton {
  color: $grey700;
  margin-left: 8px;
}

.iconButton:hover {
  color: $primary700;
}

.headerCellContainer,
.sortingContainer {
  cursor: pointer;
}

.sortingContainer > .iconButton {
  opacity: 1;
  visibility: visible;
}

.headerCellContainer > .iconButton {
  opacity: 0;
  visibility: hidden;
}

.headerCellContainer:hover > .iconButton {
  opacity: 1;
  transition: visibility 0s, opacity 0.2s linear;
  visibility: visible;
}

.title {
  color: $grey700;
  width: max-content;
}
