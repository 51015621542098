@import 'styles/partials/_variables.module.scss';

.accordionDetails{
  padding: 0 2px 2px;
}

.contentContainer {
  align-items: center;
  color: $grey700;
  column-gap: 16px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.divider {
  margin: 0 24px;
}