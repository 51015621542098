.infoCard {
  border-radius: 8px;
  box-shadow: 0 4px 30px 0 rgba(75, 169, 196, 0.07);
  column-gap: 24px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}
