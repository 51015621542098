@import 'styles/partials/_variables.module.scss';

.dot {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 8px;
  width: 10px;
}

.new {
  background-color: $green;
}

.inProgress {
  background-color: $yellow;
}

.expired {
  background-color: $red100;
}

.completed {
  background-color: $primary700;
}
