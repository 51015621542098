@import 'styles/partials/_variables.module.scss';

.instruction {
  color: $grey700;
  margin-bottom: 16px;
}

.itemFilters {
  margin-right: 0;
}

.itemSearch {
  margin: 0;
  padding: 0;
}
