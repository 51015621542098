@import 'styles/partials/_variables.module.scss';

.box {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 64px;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1120px;
  width: 100%;
}

.container {
  padding: 0;
  width: 100%;
}

.heading {
  color: $grey700;
  height: 32px;
}

.iconButton {
  height: 24px;
  width: 24px;
}

.iconSvg {
  color: $primary700;
  height: 24px;
  width: 24px;
}
