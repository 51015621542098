@import 'styles/partials/_variables.module.scss';

.commentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 81px;
}

.evaluator {
  color: $grey700;
  min-width: 83px;
}

.evaluatorCommentContainer {
  align-items: flex-start;
  display: flex;
  margin-bottom: 24px;
}
