$black: #000000;
$green: #0d8a19;
$green300: #1a703e;
$grey100: #f5f6f7;
$grey200: #eeeff0;
$grey300: #cacbcc;
$grey400: #acadad;
$grey500: #8e8e8f;
$grey600: #666666;
$grey700: #515152;
$grey800: #141414;
$orange: #c4704b;
$orange100: #f3e2db;
$orange300: #b65e41;
$primary100: #d4e5eb;
$primary200: #bfd8e1;
$primary300: #a9cbd7;
$primary400: #69a4b8;
$primary500: #5397ae;
$primary600: #3e8aa4;
$primary700: #287d9a;
$primary800: #24718b;
$primary900: #20647b;
$red: #da0101;
$red100: #d64040;
$red300: #c60101;
$secondary1_100: #d080ba;
$secondary1_200: #c766ac;
$secondary1_300: #bd4d9e;
$secondary1_400: #b43390;
$secondary1_500: #aa1a82;
$secondary1_600: #a10074;
$secondary1_700: #910068;
$secondary1_800: #81005d;
$secondary1_900: #710051;
$secondary2_100: #f8fbfd;
$secondary2_200: #edf6f9;
$secondary2_300: #e9f2f3;
$secondary2_400: #dbeef3;
$secondary2_500: #b7dde7;
$secondary2_600: #93cbdc;
$secondary2_700: #4ba9c4;
$secondary2_800: #4498b0;
$secondary2_900: #3c879d;
$white: #ffffff;
$yellow: #f6cd56;
$yellow100: #f3eedb;
$yellow300: #c4ac4b;
$yellow400: #947201;
