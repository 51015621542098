@import 'styles/partials/_variables.module.scss';

.summaryBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 24px;
}

.summaryCard {
  align-items: center;
  border-radius: $space_xs;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  color: $grey700;
  column-gap: 16px;
  display: flex;
  justify-content: flex-start;
  padding: 16px 16px 16px 24px;
  text-align: left;
}
