@import 'styles/partials/_variables.module.scss';

.instruction {
  color: $grey700;
  margin-bottom: 16px;
}

.radioButton {
  color: $primary700;
  height: 24px;
  margin-right: 16px;
  padding: 0;

  &[class*='Mui-checked'] {
    color: $primary700;
  }
}

.radioButtonLabel {
  color: $grey700;
}

.radioButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
