@import 'styles/partials/_variables.module.scss';

.tableCellText {
  color: $grey700;
  text-align: left;
  width: max-content;
}

.tableCell {
  padding: 12px 16px;
}
