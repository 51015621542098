@import 'styles/partials/_variables.module.scss';

.pendingBox {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  text-align: center;
  width: 50%;
}

.pendingCard {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  color: $grey700;
  column-gap: 40px;
  display: flex;
  justify-content: center;
  padding: 36px 40px;
}