@import 'styles/partials/_variables.module.scss';

.instruction {
  color: $grey700;
  margin-bottom: 16px;
}

.emptySubtitle {
  color: $grey600;
}
