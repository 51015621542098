@import './colors';
@import './fonts';
@import './fontSizes';
@import './fontWeights';
@import './lineHeights';
@import './spacing';

:export {
  background: $white;
  black: $black;
  errorMain: $red;
  fontNunito: $font-nunito;
  fontRoboto: $font-roboto;
  fontSize_2xl: $fontSize_2xl;
  fontSize_3xl: $fontSize_3xl;
  fontSize_4xl: $fontSize_4xl;
  fontSize_lg: $fontSize_lg;
  fontSize_md: $fontSize_md;
  fontSize_sm: $fontSize_sm;
  fontSize_xl: $fontSize_xl;
  fontSize_xs: $fontSize_xs;
  fontWeightBold: $bold;
  fontWeightMedium: $medium;
  fontWeightRegular: $regular;
  green: $green;
  green300: $green300;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey800: $grey800;
  lineHeight_2xl: $lineHeight_2xl;
  lineHeight_3xl: $lineHeight_3xl;
  lineHeight_lg: $lineHeight_lg;
  lineHeight_md: $lineHeight_md;
  lineHeight_xl: $lineHeight_xl;
  orange: $orange;
  orange100: $orange100;
  orange300: $orange300;
  primary100: $primary100;
  primary200: $primary200;
  primary300: $primary300;
  primary400: $primary400;
  primary500: $primary500;
  primary600: $primary600;
  primary700: $primary700;
  primary800: $primary800;
  primary900: $primary900;
  red: $red;
  red100: $red100;
  red300: $red300;
  secondary1_100: $secondary1_100;
  secondary1_200: $secondary1_200;
  secondary1_300: $secondary1_300;
  secondary1_400: $secondary1_400;
  secondary1_500: $secondary1_500;
  secondary1_600: $secondary1_600;
  secondary1_700: $secondary1_700;
  secondary1_800: $secondary1_800;
  secondary1_900: $secondary1_900;
  secondary2_100: $secondary2_100;
  secondary2_200: $secondary2_200;
  secondary2_300: $secondary2_300;
  secondary2_400: $secondary2_400;
  secondary2_500: $secondary2_500;
  secondary2_600: $secondary2_600;
  secondary2_700: $secondary2_700;
  secondary2_800: $secondary2_800;
  secondary2_900: $secondary2_900;
  space_2xl: $space_2xl;
  space_2xs: $space_2xs;
  space_3xl: $space_3xl;
  space_lg: $space_lg;
  space_md: $space_md;
  space_sm: $space_sm;
  space_xl: $space_xl;
  space_xs: $space_xs;
  successMain: $green;
  warningMain: $orange;
  white: $white;
  yellow: $yellow;
  yellow100: $yellow100;
  yellow300: $yellow300;
  yellow400: $yellow400;
}
