@import 'styles/partials/_variables.module.scss';

.counterBoxLeft,
.counterBoxRight {
  align-items: center;
  column-gap: 24px;
  display: flex;
  text-align: left;
  width: 50%;
}

.counterBoxLeft {
  justify-content: flex-end;
}

.counterBoxRight {
  justify-content: flex-start;
}

.counterCard {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  color: $grey700;
  column-gap: 40px;
  display: flex;
  justify-content: center;
  padding: 16px 40px;
}