@import 'styles/partials/_variables.module.scss';

.text {
  color: $grey700;
  display: inline;
  width: 100%;
}

.viewButton {
  background-color: $white;
  color: $secondary1_600;
  font-size: 16px;
  height: fit-content;
  justify-content: flex-start;
  margin-top: 8px;
  padding: 0;
  transition: none;
  width: fit-content;
  &:hover {
    background-color: $white;
  }
}
