@import 'styles/partials/_variables.module.scss';

.companyTotalCard,
.departmentTotalCard,
.totalCard {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  color: $grey700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  text-align: center;
}

.companyTotalCard,
.departmentTotalCard {
  padding: 88px 46px;
}

.totalCard {
  padding: 36px 92px;
}