@import 'styles/partials/_variables.module.scss';

.avatar {
  height: 88px;
  margin-bottom: 24px;
  width: 88px;
}

.rootCard {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  display: flex;
  flex-direction: column;
  padding: 40px 48px;
}

.textBox {
  align-items: 'flex-start';
  color: $grey700;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
}