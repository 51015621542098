.iconSVG{
  margin-left: 10px;
  padding: 0;
}

.tooltipPopper{
  max-width: 216px;
  div[class*='MuiTooltip-tooltip']{
    margin: 0;
  }
}
