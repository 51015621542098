@import 'styles/partials/_variables.module.scss';

.bottomRated {
  background-color: $orange100;
}

.label {
  padding: 0;
}

.topRated {
  background-color: rgba($green, 0.1);
}

.bottomRated,
.topRated {
  border-radius: $space_xs;
  height: 28px;
  padding: $space_xs;
}