@import 'styles/partials/_variables.module.scss';

.upcomingCard {
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  color: $grey700;
  display: flex;
  flex-direction: column;
  padding: 24px 36px;
  row-gap: 16px;
}