@import 'styles/partials/_variables.module.scss';

.cardRoot {
  box-shadow: 0 4px 15px 0 rgba(32, 100, 123, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 308px;
  padding: 24px;
}

.clearFilterButton {
  color: $primary800;
  font-size: $fontSize_sm;
  font-weight: 600;
}

.filterCheckBox {
  color: $primary700;
  margin-right: 8px;
  padding: 0;
}
