@import 'styles/partials/_variables.module.scss';

.instruction {
  color: $grey700;
  margin-bottom: 16px;
}

.itemDropdown {
  margin-right: 20px;
}

.orginalPath {
  color: $grey700;
}

.rootCard {
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  height: 100%;
  margin: 24px 0 20px;
  padding: 40px 44px;
  width: 100%;
}
