@import 'styles/partials/_variables.module.scss';

.description,
.noComments {
  color: $grey700;
}

.evaluator {
  color: $grey700;
  min-width: 83px;
}

.divider {
  margin: 24px -16px;
}

.evaluatorsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 16px 0;
}

.kpiScore {
  color: $grey700;
}

.rootContainer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  height: fit-content;
  overflow: hidden;
  padding: 28px 40px 16px;
  width: 100%;
}

.scoreContainer {
  align-items: baseline;
  column-gap: 8px;
  display: flex;
  margin-right: 20px;
}
