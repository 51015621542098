@import 'styles/partials/_variables.module.scss';

.quarterTabsContainer {
  display: flex;
  height: 50px;
  justify-content: space-between;
  width: 100%;
}

.tab{
  color: $grey700;
  height: 24px;
  padding: 0;
  width: 24px;
  &[aria-selected = true]{
    color: $primary700;
  }
  &[disabled]{
    color: $grey500;
  }
}

.tabsContainer {
  display: flex;
  justify-content: space-around;
  width: 187px;
  div[class*='MuiTabs-flexContainer']{
    gap: 24px;
  }
  div[class*='MuiTabs-scroller']{
    margin-top: 18px;
  }
  span[class*='MuiTabs-indicator']{
    bottom: 0;
  }
}

.dropdownContainer {
  width: 160px;
}