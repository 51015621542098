@import 'styles/partials/_variables.module.scss';

.baseTitle {
  color: $primary700;
}

.baseSubtitle {
  color: $grey700;
}

.topRatedTitle {
  color: $green;
}

.worstRatedTitle {
  color: $orange;
}
