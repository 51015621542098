.actionBox {
  column-gap: 24px;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  padding-left: 120px;
}

.bodyText {
  margin-top: 12px;
  white-space: pre-wrap;
}

.rootBox {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 25px 0 rgba(25, 57, 66, 0.1);
  grid-area: 1/4/2/10;
  height: fit-content;
  justify-self: center;
  margin-bottom: 40px;
  padding: 32px 40px;
  width: fit-content;
}

.rootModal {
  align-items: center;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: fit-content;
  height: 100%;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
}
