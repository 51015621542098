@import 'styles/partials/_variables.module.scss';

.appBarContainer {
  align-items: center;
  background-color: $white;
  color: $grey700;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 80px;
}

.badge {
  span[class*='MuiBadge-dot'] {
    border: 1px solid $white;
    height: 10px;
    right: 30%;
    top: 30%;
    width: 10px;
  }
}

.menuButtonsContainer,
.navBarActionsContainer,
.navLinksContainer {
  display: flex;
}

.notificationsButton,
.userAvatar,
.userButton {
  height: 32px;
  padding: 0;
  width: 32px;
}
