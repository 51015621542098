@import 'styles/partials/_variables.module.scss';

.layout {
  max-width: 1120px;
}

.layoutMain {
  background-color: $secondary2_100;
  min-height: calc(100vh - 64px);
  padding: 24px 40px 40px;
}
