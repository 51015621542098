@import 'styles/partials/_variables.module.scss';

.backgroundBox {
  align-items: center;
  background-image: url('/assets/images/loginBackground.svg');
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.bodyText {
  color: $black;
  font-stretch: normal;
  font-style: normal;
  height: 24px;
  margin: 12px 0 24px;
  text-align: center;
  width: 402px;
}

.buttonMSLogin {
  margin: 0;
  padding: 0;
  &:hover {
    background-color: $white;
  }
}

.cardContainer {
  display: flex;
  justify-content: flex-end;
  max-width: 1280px;
  width: 100%;
}

.cardContent {
  align-items: center;
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 385px;
  justify-content: center;
  margin: 77px 80px 86px 24px;
  padding: 16px;
  width: 548px;
}

.logInText {
  font-stretch: normal;
  font-style: normal;
  height: 32px;
  margin: 32px 96px 12px;
  text-align: center;
  width: 166px;
}

.loginImage {
  display: flex;
  margin: 0 24px 0 0;
  max-height: 548px;
  max-width: 548px;
  object-fit: contain;
}

.performanceAppText {
  color: #325d7f;
  height: 56px;
  width: 314px;
}
