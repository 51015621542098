.gridRoot {
  row-gap: 44px;
}

.itemFilters {
  margin-right: 48px;
}

.itemSearch {
  margin: 0 -96px 0 48px;
  padding-right: 96px;
}

.itemTable {
  margin: 0 48px;
}

.rootBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rootCard {
  border-radius: 8px;
  box-shadow: 0 4px 30px 0 rgba(75, 169, 196, 0.07);
  height: 100%;
  margin: 24px 0 20px;
  padding: 40px 0 20px;
  width: 100%;
}