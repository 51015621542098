@import 'styles/partials/_variables.module.scss';

.row:hover {
  background-color: $grey100;
}

.lastRow {
  border-bottom: 1px solid $white;
}

.lastRow:hover {
  background-color: $grey100;
}
