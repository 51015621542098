@import 'styles/partials/_variables.module.scss';

.cardMedia {
  height: 72px;
  object-fit: contain;
  width: 72px;
}

.cardRoot {
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
  padding: 40px 0;
}

.collaboratorText {
  color: $grey700;
  margin-bottom: 8px;
}

.descriptionText {
  color: $grey700;
  padding-left: 8px;
}

.titleText {
  color: $primary900;
  margin-bottom: 12px;
}
