@import 'styles/partials/_variables.module.scss';

.cardTitle {
  margin-bottom: 24px;
}

.cardRoot {
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);
  padding: 40px 0;
}

.commentsCharCount {
  align-self: flex-end;
  margin: 8px 0 0;
}

.description {
  margin-bottom: 24px;
  text-align: left;
}

.formField {
  margin-bottom: 48px;
}

.radioButton {
  color: $primary700;
  height: 24px;
  margin-right: 16px;
  padding: 0;

  &[class*='Mui-checked'] {
    color: $primary700;
  }
}

.radioButtonsContainer {
  display: flex;
  gap: 16px;
}
