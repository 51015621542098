@import 'styles/partials/_variables.module.scss';

.assigned {
  color: $black;
  font-weight: $bold;
}

.itemFilters {
  margin-right: 0;
}

.itemSearch {
  margin: 0;
  padding: 0;
}

.instruction {
  margin-bottom: 16px;
}

.emptySubtitle {
  color: $grey600;
}

.orginalPath {
  color: $grey700;
}

.rootCard {
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(32, 100, 123, 0.1);
  height: 100%;
  margin: 24px 0 20px;
  padding: 40px 44px;
  width: 100%;
}

.tableCell {
  color: $grey700;
}
