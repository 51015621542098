@import 'styles/partials/_variables.module.scss';

.iconSvg {
  color: $primary700;
}

.menuItems {
  li[class*='MuiMenuItem-root']:not(:last-child){
    border-bottom: 1px solid $grey300;
    left: 20%;
    padding-left: 0;
    width: 40px;
  }
  li[class*='Mui-selected']{
    background-color: inherit;
    color: $primary700;
  }
}

.selectPagination {
  div[class*='MuiSelect-select-MuiInputBase-input']{
    padding-left: 15px;
    text-align-last: left;
    width: 30px;
  }
  div[class*='MuiSelect-select-MuiInputBase-input']:focus{
    background-color: inherit;
  }
}

.selectInput {
  color: $grey700;
  font-size: 0.875rem;
  line-height: inherit;
  margin-right: 4px;
}
