@import 'styles/partials/_variables.module.scss';

.iconButton {
  margin: 12px 8px 12px 16px;
  padding: 0;
}

.iconSvg {
  color: $primary700;
  height: 24px;
  width: 24px;
}

.inputBaseInput {
  padding: 0;
  &::placeholder {
    color: $grey700;
    opacity: 1;
  }
  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }
}

.inputBaseRoot {
  margin: 12px 16px 12px 0;
}

.paperRoot {
  align-items: stretch;
  border: solid 1px;
  border-color: $grey500;
  border-radius: 8px;
  box-shadow: none;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 100%;
}
